.circleIcon {
  background-color: #fff;
  cursor: pointer;
}

.searchIcon {
  border: 2px solid #ffffff;
  border-radius: 50px;
  background-color: transparent !important;
}

.headericon-lg {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.headerhmlogo-lg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}
.headerTitle-lg {
  position: absolute;
  top: 24px;
  line-height: 38px;
  /* identical to box height */
  color: #cc071e;
}
.headersearch {
  padding-right: 10px;
  margin-right: 0;
}
.search-control {
  height: 35px !important;
  border-radius: 30px;
  background-color: transparent;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.4);
  border-left-color: white;
}
.profileAvatar {
  background-color: #fdfdfd;
  color: #000000 !important;
}

.logoutSpriteIcon {
  background-image: url('../images/svgs/spriteSVGs.svg');
  width: 32px;
  height: 30px;
  background-position: -133px -79px;
  display: inline-flex;
  align-items: center;
}

.notificationSpriteIcon {
  background-image: url('../images/svgs/spriteSVGs.svg');
  width: 32px;
  height: 32px;
  background-position: -80px -78px;
  display: inline-flex;
  align-items: center;
}

.bellPopNav {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  max-width: 202px;

  &.nav-tabs {
    --cui-nav-tabs-border-width: 0px;

    .nav-item {
      .nav-link {
        font-style: normal;
        font-weight: 300;
        font-size: inherit;
        color: #ffffff;
        cursor: pointer;
        margin: 5px;
        height: 28px;
        width: 91px;
        line-height: 13px;
        text-align: center;

        &.active {
          background: #243e5f;
          border-radius: 50px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
