// Here you can add other styles

.background {
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.app.dark + .wrapper-img {
  background: radial-gradient(
    138.96% 81.81% at 32.56% 35.26%,
    #435268 0%,
    #1d3551 36.98%,
    #0d2337 66.84%,
    #1d3551 100%
  );
  position: relative;
}
.wrapper-img {
  background: radial-gradient(
    138.96% 81.81% at 32.56% 35.26%,
    #435268 0%,
    #1d3551 36.98%,
    #0d2337 66.84%,
    #1d3551 100%
  );
  position: relative;
}
.app.light + .wrapper-img {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  // background-image: url(../images/IntegrationMontioring/lightBackground.png);
  background: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 93vh;
}
.px-2 {
  padding-left: 0px;
  padding-right: 0px;
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button {
  background-color: #000000;
  color: #ffffff;
}
.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}
.header {
  background-color: #0f1e30 !important;
  border: none !important;
}

.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}
.breadcrumb-item::before {
  content: '' !important;
}
.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}
.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-size: 24px;
  color: #ffffff;
}
.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.bg-row1 {
  background-color: #2c2c2c;
  border: 1px solid #bab7b7;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  height: 100%;
}
.colvaluewidth {
  width: 26.2%;
}
.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}
.bg-row1-col1 {
  border-top-left-radius: 10px;
}
.bg-row1-col6 {
  border-top-right-radius: 10px;
}
.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;
  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }
  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}
.guage-alignment {
  padding-inline-start: 5px;
}
.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }
  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }
  .cardPercentage {
    font-size: 13px;
    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;
      &.upIcon {
        background: url(../images/up-icon.png);
      }
      &.downIcon {
        background: url(../images/down-icon.png);
      }
    }
    .percentageText {
      color: #555555;
      &.upIconText {
        color: #579c21;
      }
      &.downIconText {
        color: #e44659;
      }
    }
  }
  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;
    img {
      width: 50px;
    }
  }
}
.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}
.mmr_box {
  width: 56px;
  height: 37px;
  overflow: hidden;
  border-radius: 4px;
}
.bg-red {
  background: #ea3323;
  border: 2px solid #ffffff;
}
.bg-green {
  background: #377e22;
  border: 2px solid #ffffff;
}
.mmr_mms {
  // background: linear-gradient(
  //     8.07deg,
  //     #ffffff 4.72%,
  //     rgba(210, 210, 210, 0.819345) 28.16%,
  //     rgba(217, 217, 217, 0) 88.65%
  //   ),
  //   linear-gradient(0deg, #d9d9d9, #d9d9d9), rgba(13, 25, 39, 0.2);
  // opacity: 0.6;
  border: 1px solid rgba(244, 244, 244, 0.3);
  border-radius: 36px;
  background: linear-gradient(8.07deg, #8a93a2 4.72%, #8a93a2 28.16%, rgba(217, 217, 217, 0) 88.65%),
    linear-gradient(0deg, #8a93a2, #8a93a2), #4f5d73;
}
.mmr_mms_application {
  background: linear-gradient(
      8.07deg,
      #ffffff 4.72%,
      rgba(210, 210, 210, 0.819345) 28.16%,
      rgba(217, 217, 217, 0) 88.65%
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9), rgba(13, 25, 39, 0.2);
  // opacity: 0.6;
  border: 1px solid rgba(244, 244, 244, 0.3);
  min-height: 80vh;
  border-radius: 36px;
}
.mmr_box .mms_box {
  position: relative;
  width: 38px;
  height: 19px;
  margin: auto;
  margin-top: 14%;
  // z-index: 1;
  border-radius: 4px;
}
.card_mmr_mms {
  background: linear-gradient(360deg, #ffffff -8.58%, rgba(217, 217, 217, 0) 224.06%);
  border: 1px solid #fefefe;
  border-radius: 12px;
  height: 244px;
}
.card_mmr_mms > text {
  font-weight: 700 !important;
}
.dropdowntoggle_mmr_mms {
  color: white;
  background: #4a575e;
  border: #4a575e;
}
.mmr_completion {
  color: black;
  background: #cccbb6;
}
.mms_completion {
  color: black;
  background: #e3e9d9;
}
.mmr_mms_boxes {
  background: linear-gradient(360deg, #ffffff -8.58%, rgba(217, 217, 217, 0) 224.06%);
  border-radius: 12px;
  color: white;
  height: 415px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .statusBox {
    height: 313px;
    overflow-y: scroll;
  }
  .statusBox::-webkit-scrollbar {
    width: 5px;
    background: #f5f1f19d;
    border-radius: 4px;
  }

  .statusBox::-webkit-scrollbar-thumb {
    background: #929191;
    border: 0.5px solid #929191;
    border-radius: 4px;
  }
}
.heading_mmr_mms_box {
  background: linear-gradient(180deg, #ffffff 0%, rgba(232, 232, 232, 0) 100%);
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  height: 49.32px;
  color: #000000;
}
.filterDropdownDiv {
  background: linear-gradient(360deg, #ffffff -8.58%, rgba(217, 217, 217, 0) 224.06%);
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  margin: 20px 20px;
  // background-color: #1eb488;
}
.filterDropdownRow {
  background: linear-gradient(360deg, #c7c7c7 -8.58%, rgba(217, 217, 217, 0) 224.06%);
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 12px;
  // background-color: #377e22;
}

.chartLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 2px solid black;
  background: radial-gradient(
    78.1% 78.1% at 30.98% 28.96%,
    #ffffff 44%,
    #fafafa 54%,
    #ededed 64%,
    #d7d7d7 75%,
    #b8b8b8 86%,
    #b2b2b2 88%,
    #b4b4b4 94%,
    #bcbcbc 97%,
    #c9c9c9 99%,
    #d2d2d2 100%
  );
  color: #767676;
  word-break: break-all;
}
.chartLabelPercentage {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.chartHeadingDiv {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
}
.chartHeading {
  opacity: 0.5;
  font-family: 'HM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* identical to box height, or 122% */
  text-align: center;
  text-transform: uppercase;
  color: #111010;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.chartLegend {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  color: #000000;
}
.btn-login-button,
.btn-login-button:hover,
.btn-login-button:active,
.btn-login-button:focus,
.btn-login-button:visited {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #000000 !important;
}
.app-header {
  background: black;
}
.font-style {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
// .app.dark {
//   background-color: #121212 !important;
//   color: #ffffff !important;
// }
.integration-monitoring-text {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-padding {
  // padding: 30px 0px 50px 0px !important;
  // max-width: 1440px;
}
.light {
  .card-description-text {
    color: #231f20 !important;
  }
  // .card-header-text {
  //   color: #231f20 !important;
  // }
}
// .card-header-text {
//   color: #fff;
// }
// }
.display-position {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.closeIcon-Container {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 1000;
}
// .close-btn {
//   background-color: #000;
//   border-radius: 12px;
// }
.text-right {
  text-align: right !important;
}
.red-circle {
  width: 15px;
  height: 15px;
  background: #e21a1a;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.green-circle {
  width: 15px;
  height: 15px;
  background: #119605;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.recharts-tooltip-wrapper,
.recharts-wrapper {
  outline: none;
}
// .path.recharts-sector,
// .recharts-layer {
//   outline: none;
//   pointer-events: none;
// }
.justify-space-around {
  justify-content: space-around;
}
.cursor-pointer {
  cursor: pointer !important;
}
.white-circle-icon {
  right: 20px;
  bottom: -19px;
}
.justify-space-between {
  justify-content: space-between;
}
